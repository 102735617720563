import { createRouter, createWebHistory } from 'vue-router';
import { supabase } from '../supabase';

function loadPage(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`
    );
}

// const routes = [
//   {
//     path: '/',
//     name: 'Dashboard',
//     component: loadPage("Dashboard"),
//     meta: {
//       requiresAuth: true,
//     }
//   },
//   {
//     path: '/sign-up',
//     name: 'SignUp',
//     component: loadPage("SignUp")
//   },
//   {
//     path: '/sign-in',
//     name: 'SignIn',
//     component: loadPage("SignIn")
//   },
// ]
const routes = [
  {
    path: '/',
    component: loadPage("Layouts/Default2"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Index',
        component: loadPage("Dashboard"),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: loadPage("Profile"),
      },
      {
        path: '/orders',
        name: 'Orders',
        children: [
          {
            path: '',
            name: 'OrdersIndex',
            component: loadPage("Orders/Index"),
          },
          {
            path: ':id',
            name: 'OrdersItem',
            component: loadPage("Orders/Item"),
          },
          {
            path: 'create',
            name: 'OrdersCreate',
            component: loadPage("Orders/Create"),
          }
        ]
      },
      {
        path: '/contacts',
        name: 'Contacts',
        children: [
          {
            path: '',
            name: 'ContactsIndex',
            component: loadPage("Contacts/Index"),
          },
          {
            path: ':id',
            name: 'ContactsItem',
            component: loadPage("Contacts/Item"),
          },
          {
            path: 'create',
            name: 'ContactsCreate',
            component: loadPage("Contacts/Create"),
          }
        ]
      },
      {
        path: '/mysteries',
        name: 'MysteriesIndex',
        children: [
          {
            path: '',
            name: 'MysteriesIndex',
            component: loadPage("Mysteries/Index"),
          },
          {
            path: ':id',
            name: 'MysteriesItem',
            component: loadPage("Mysteries/Item"),
            props: true,
            meta: {
              showModal: true
            }
          },
          {
            path: 'create',
            name: 'MysteriesCreate',
            component: loadPage("Mysteries/Create"),
          }
        ]
      },
    ]
  },
  {
    path: '/auth',
    component: loadPage("Layouts/Auth2"),
    children: [
      {
        path: '',
        redirect: '/auth/sign-in'
      },
      {
        path: 'sign-up',
        name: 'SignUp',
        component: loadPage("Auth/SignUp")
      },
      {
        path: 'sign-in',
        name: 'SignIn',
        component: loadPage("Auth/SignIn2")
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: loadPage("Auth/ResetPassword")
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const currentUser = supabase.auth.user();
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

//   if(requiresAuth && !currentUser) next('sign-in');
//   else if(!requiresAuth && currentUser) next("/");
//   else next();
// })
router.beforeEach((to, from, next) => {
  const currentUser = supabase.auth.user();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if(requiresAuth && !currentUser) next('/auth/sign-in');
  else if(!requiresAuth && currentUser) next("/");
  else next();
})

export default router
