export default {
  namespaced: true,
  state: () => ({
    orders: {
      defaultTab: 0
    },
  }),
  getters: {
    GET_ORDERS_DEFAULT_TAB: (state) => {
      return state.orders.defaultTab
    }
  },
  mutations: {
    SET_ORDERS_DEFAULT_TAB: (state, payload) => {
      state.orders.defaultTab = payload
    }
  },
  actions: {
    setOrdersDefaultTab({ commit }, payload) {
      commit('SET_ORDERS_DEFAULT_TAB', payload)
    }
  }
}