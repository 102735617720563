import delay from 'lodash/delay'
import find from 'lodash/find'
import clone from 'lodash/clone'
import Vue from 'vue'

import { supabase } from "../../supabase";

import { useToast } from 'vue-toast-notification';
const $toast = useToast();

export default {
  namespaced: true,
  state: () => ({
    list: [],
    steps: []
  }),
  getters: {
    // orders(state) {
    //   return state.list
    // },
    // orderByStep({ state }, step) {
    //   return state.list.filter(o => {
    //     return o.step == step
    //   })
    // },
    GET_LIST(state) {
      return state.list
    },
    GET_KANBAN(state) {
      let columns = [
        {
          step: 0,
          title: 'state.steps[0]',
          list: []
        },
        {
          step: 1,
          title: 'state.steps[1]',
          list: []
        },
        {
          step: 2,
          title: 'state.steps[2]',
          list: []
        },
        {
          step: 3,
          title: 'state.steps[3]',
          list: []
        }
      ]

      state.list.forEach(o => {
        // console.log(columns)
        const column = find(columns, { step: o.step })
        // console.log(column)
        if (column) {
          column.list.push(o)
          // console.log(column)
        }
      })

      return columns
    }
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
    SET_STEPS_LIST(state, payload) {
      state.steps = payload
    },
    UPDATE_ITEM(state, payload) {
      // state.list = state.list.map(item => {
      //   if (item.id === payload.id) {
      //     return Object.assign({}, item, payload)
      //   }
      //   console.log(item)
      //   return item
      // })

      const index = state.list.findIndex(o => o.id === payload.id)

      // state.list.splice(index, 1, payload)
      // Vue.set(state.list[index], 'step', payload.step)

      state.list[index] = payload

      // let tmp = clone(state.list)
      // console.log(tmp)

    },
    UPDATE_KANBAN(state, payload) {

    }
  },
  actions: {
    async fetchSteps({ commit }) {

      try {
        let { data: order, error } = await supabase
          .from('step')
          .select('*')
        if (error) throw error;
        // alert("You've been registered successfully");
        $toast.success("Steps update successfully");
        commit("SET_STEPS_LIST", order)
      } catch (error) {
        // alert(error.error_description || error.message);
        $toast.error(error.error_description || error.message);
      }
    },
    async fetchOrders({ commit }, payload) {
      try {
        let { data: order, error } = await supabase
          .from('order')
          .select(`
            id, 
            title, 
            step,
            created_at,
            contact (
              id,
              firstName,
              secondName,
              lastName
            ),
            mystery (
              id,
              spectacle (
                id,
                title
              )
            )
          `)
        if (error) throw error;
        // alert("You've been registered successfully");
        $toast.success("Orders update successfully");
        commit("SET_LIST", order)
      } catch (error) {
        // alert(error.error_description || error.message);
        $toast.error(error.error_description || error.message);
      }
    },
    async updateOrder({ commit }, payload) {
      // categoriesApi.updateCategory(payload.id, payload.data).then((response) => {
      //   commit(mutationType.UPDATE_CATEGORY, payload);
      //   router.push({ name: 'categories' });
      // })

      const { data, error } = await supabase
        .from('order')
        .update({ step: payload.step })
        .eq('id', payload.id) 
        // console.log(data[0])
      commit('UPDATE_ITEM', data[0]);
    }
  }
}