'use strict'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Toast from "vue-toastification"
// import "vue-toastification/dist/index.css";

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

createApp(App).use(store).use(router).use(ToastPlugin).mount('#app')
// createApp(App).use(store).use(router).use(Toast).mount('#app')
// createApp(App).use(store).use(router).mount('#app')
