import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import router from '../router';
import { supabase } from "../supabase";

import { useToast } from 'vue-toast-notification';
const $toast = useToast();

import order from '@/store/modules/order'
import mystery from '@/store/modules/mystery'
import contact from '@/store/modules/contact'
import ui from '@/store/modules/ui'

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    signInAction: async ({ commit }, form) => {
      try {
        const { error, user } = await supabase.auth.signIn({
          email: form.email,
          password: form.password,
        });
        if (error) throw error;
        // alert("You've Signed In successfully");
        $toast.success("You've Signed In successfully!")
        await router.push('/')
        commit('setUser', user.email)
      } catch (error) {
        // alert(error.error_description || error.message);
        $toast.error(error.error_description || error.message)
      }
    },

    async signUpAction({dispatch}, form) {
      try {
        const { error} = await supabase.auth.signUp({
          email: form.email,
          password: form.password,
        });
        if (error) throw error;
        // alert("You've been registered successfully");
        $toast.success("You've been registered successfully");
        await dispatch("signInAction", form)
      } catch (error) {
        // alert(error.error_description || error.message);
        $toast.success(error.error_description || error.message);
      }
    },

    async signOutAction({ commit }) {
      try {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;
        commit('setUser', null)
        // alert("You've been logged Out successfully");
        $toast.success("You've been logged Out successfully");
        await router.push("/auth/sign-in");
      } catch (error) {
        // alert(error.error_description || error.message);
        $toast.error(error.error_description || error.message);
      }
    },
  },
  modules: {
    order,
    mystery,
    contact,
    ui
  },

  plugins: [createPersistedState({
    paths: ['', 'ui']
  })],

})
