<template>
  <div>
    <router-view />
  </div>
</template>

<style>
</style>

<script setup>

import { onMounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

onMounted(async () => {
  console.log('init')
  await store.dispatch('order/fetchOrders')
  // await store.dispatch('order/fetchSteps')
  await store.dispatch('mystery/fetchList')
  await store.dispatch('contact/fetchList')
})

</script>