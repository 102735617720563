import { DateTime } from 'luxon'

const getPagination = (page, size) => {
  const limit = size ? +size : 3
  const from = page ? page * limit : 0
  const to = page ? from + size : size

  return { from, to }
}

const dateTimeLocale =(date) => {
  return DateTime.fromISO(date).toFormat(`yyyy-MM-dd'T'HH:mm`)
}

const getMysteryTitle = (o) => {
  return `${o.spectacle.title} ${DateTime.fromISO(o.date).setLocale('ru').toLocaleString(DateTime.DATE_SHORT)}`
}

const getFullName = (o) => {
  return `${o.lastName} ${o.firstName} ${o.secondName}`.trim() 
}

export {
  getPagination,
  getFullName,
  getMysteryTitle,
  dateTimeLocale
}