// import delay from 'lodash/delay'
import { supabase } from "../../supabase";

import { useToast } from 'vue-toast-notification';
const $toast = useToast();
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { getFullName } from '@/utils.js'

export default {
  namespaced: true,
  state: () => ({
    list: [],
    currentItem: null,
    model: {
      firstName: '',
      secondName: '',
      lastName: '',
      phone: '',
      email: ''
    }
  }),
  getters: {
    GET_MODEL(state) {
      return state.model
    },
    GET_LIST(state) {
      return state.list
    },
    GET_ITEM: (state) => ({id}) => {
      // let item = state.model

      const tmp = state.list.find(o => {
        if (o.id == id) {
          return o
        }
      })

      // if (tmp) {
      //   item = tmp
      // }

      // return item
      return tmp
    },
    GET_CURRENT_ITEM: (state) => {
      return state.currentItem
    }
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
    APPEND_ITEM(state, payload) {
      payload['fullName'] = getFullName(payload)
      state.list.push(payload)
      state.list = [...state.list]
    },
    UPDATE_ITEM(state, payload) {
      const index = state.list.findIndex(o => o.id === payload.id)

      payload['fullName'] = getFullName(payload)

      if (index !== -1) {
        state.list[index] = payload
      } else {
        state.list.push(payload)
      }
      state.list = [...state.list]
    },
    DELETE_ITEM(state, payload) {
      console.log(payload)
      const index = state.list.findIndex(o => o.id == payload.id)
      console.log(index)
      if (index !== -1) {
        let list = state.list
        list.splice(index, 1)

        // console.log(list)

        state.list = [...list]
      } 
    },
    SET_CURRENT_ITEM(state, payload) {
      state.currentItem = payload
    }
  },
  actions: {
    async fetchList({ commit }, payload) {
      try {
        let { data: contacts, error } = await supabase
          .from('contact')
          .select('*')
        if (error) throw error;

        contacts.map(o => {
          o['fullName'] = getFullName(o)
        })

        $toast.success("Contacts update successfully");
        commit("SET_LIST", contacts)
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
    async updateItem({ commit }, { id, payload }) {
      try {
        const { data: contact, error } = await supabase
          .from('contact')
          // .update(omit(payload, ['id', 'fullName']))
          .update(pick(payload, ['firstName', 'secondName', 'lastName', 'phone', 'email']))
          .eq('id', id)

        if (error) throw error

        $toast.success("Contact update successfully")
        commit("UPDATE_ITEM", contact[0])
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
    async createItem({ commit }, payload) {
      try {
        const { data: contact, error } = await supabase
          .from('contact')
          .insert(pick(payload, ['firstName', 'secondName', 'lastName','phone','email']))

        if (error) throw error;
        commit("APPEND_ITEM", contact[0])
        $toast.success("Contact create successfully")
      } catch (error) {
        $toast.error(error.error_description || error.message);
        console.error(error.error_description || error.message)
      }
    },
    async deleteItem({ commit }, payload) {
      try {
        const { data: contact, error } = await supabase
          .from('contact')
          .delete()
          .eq('id', payload.id)

        if (error) throw error;

        commit("DELETE_ITEM", payload)
        $toast.success("Contact delete successfully")
      } catch (error) {
        $toast.error(error.error_description || error.message);
        console.error(error.error_description || error.message)
      }
    },
    async getItem({ commit, getters }, id ) {
      const tmp1 = getters['GET_ITEM']({ id: id })
      console.log(tmp1)
      if (tmp1) return tmp1
      
      try {
        const { data: contact, error } = await supabase
          .from('contact')
          // .update(omit(payload, ['id', 'fullName']))
          // .update(pick(payload, ['firstName', 'secondName', 'lastName', 'phone', 'email']))
          .select('*')
          .eq('id', id)

        if (error) throw error

        // $toast.success("Contact update successfully")
        // commit("UPDATE_ITEM", contact[0])

        return contact[0]

      } catch (error) {
        $toast.error(error.error_description || error.message);
        console.error(error.error_description || error.message)
      }
    },
  }
}