import delay from 'lodash/delay'
import { supabase } from "../../supabase";

import omit from 'lodash/omit'

import { useToast } from 'vue-toast-notification';
import { getMysteryTitle } from '@/utils'

const $toast = useToast();

export default {
  namespaced: true,
  state: () => ({
    list: [],
    model: {
      spectacle_id: '',
      date: '',
      spectacle: {
        id: '',
        title: ''
      }
    },
    spectacles: []
  }),
  getters: {
    GET_MODEL(state) {
      return state.model
    },
    GET_LIST(state) {
      return state.list
    },
    GET_ITEM: (state) => ({ id }) => {
      let item = state.model

      const tmp = state.list.find(o => {
        if (o.id == id) {
          return o
        }
      })

      if (tmp) {
        item = tmp
      }

      return item
    },
    GET_SPECTACLES: (state) => {
      return state.spectacles
    }
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload
    },
    APPEND_ITEM(state, payload) {
      // payload['title'] = getMysteryTitle(payload)
      state.list.push(payload)
      state.list = [...state.list]
    },
    UPDATE_ITEM(state, payload) {
      const index = state.list.findIndex(o => o.id === payload.id)
      
      // payload['fullName'] = getFullName(payload)
      
      if (index !== -1) {
        state.list[index] = payload
      } else {
        state.list.push(payload)
      }
      state.list = [...state.list]
    },
    DELETE_ITEM(state, payload) {
      console.log(payload)
      const index = state.list.findIndex(o => o.id == payload.id)
      console.log(index)
      if (index !== -1) {
        let list = state.list
        list.splice(index, 1)

        state.list = [...list]
      }
    },
    SET_SPECTACLES(state, payload) {
      state.spectacles = payload
    },
  },
  actions: {
    async fetchList({ commit }, payload) {

      try {
        let { data: order, error } = await supabase
          .from('mystery')
          .select(`
            id,
            date, 
            created_at,
            spectacle (
              id,
              title
            ),
            mystery_member(
              id
            )
          `)
        if (error) throw error;
        // alert("You've been registered successfully");
        $toast.success("Mysteries update successfully");
        commit("SET_LIST", order)
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
    async updateItem({ commit }, { id, payload }) {
      try {
        const { data: items, error } = await supabase
          .from('mystery')
          .update(omit(payload, ['id', 'spectacle', 'mystery_member']))
          .eq('id', id)
          .select(`
            id,
            date, 
            created_at,
            spectacle (
              id,
              title
            ),
            mystery_member(
              id
            )
          `)

        if (error) throw error

        $toast.success("Mystery update successfully")
        commit("UPDATE_ITEM", items[0])
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
    async createItem({ commit }, payload) {
      try {
        const { data: items, error } = await supabase
          .from('mystery')
          .insert(omit(payload, ['spectacle']))
          .select(`
            id,
            date, 
            created_at,
            spectacle (
              id,
              title
            ),
            mystery_member(
              id
            )
          `)

        if (error) throw error;
        // commit("APPEND_ITEM", {
        //   id: items[0].id,
        //   date: items[0].date,
        //   spectacle: payload.spectacle
        // })
        commit("APPEND_ITEM", items[0])
        $toast.success("Mystery create successfully")
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
    async deleteItem({ commit }, payload) {
      try {
        const { data: item, error } = await supabase
          .from('mystery')
          .delete()
          .eq('id', payload.id)

        if (error) throw error;

        commit("DELETE_ITEM", payload)
        $toast.success("Mystery delete successfully")
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
    async fetchSpectacles({ commit }, payload) {

      try {

        // let spectacles = []
        let error = null

        if (payload.search) {
          const { data: spectacles, error } = await supabase
            .from('spectacle')
            .select(`
              id,
              title
            `)
            .ilike('title', payload.search)

          // if (error) throw error;
        } else {
          const { data: spectacles, error } = await supabase
            .from('spectacle')
            .select(`
              id,
              title
            `)

          // if (error) throw error;

          return spectacles
        }
 
        if (error) throw error;
        // alert("You've been registered successfully");
        // $toast.success("Mysteries update successfully");
        // commit("SET_SPECTACLES", spectacles)
        // return spectacles
      } catch (error) {
        $toast.error(error.error_description || error.message);
      }
    },
  }
}